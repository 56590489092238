.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: transparent;
  line-height: 1.47058824;
  cursor: pointer;
  color: #fff;
  padding: 30px 40px;
  font-weight: 500;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 2px solid #A2D9D1;
}
@media (max-width: 1199px) {
  .button {
    padding: 15px 20px;
  }
}
body.c1 .button {
  color: #A2D9D1;
  border-color: #A2D9D1;
}
body.c2 .button {
  color: #AC7979;
  border-color: #AC7979;
}
body.c3 .button {
  color: #B9B927;
  border-color: #B9B927;
}
body.c4 .button {
  color: #DD8B3D;
  border-color: #DD8B3D;
}
body.c5 .button {
  color: #919FC4;
  border-color: #919FC4;
}
body.c6 .button {
  color: #AD9B79;
  border-color: #AD9B79;
}
.button:hover,
.button:focus {
  border-color: #000000 !important;
  color: #000000 !important;
}
.button:active {
  border-color: #000000;
  color: #000000;
}
/*# sourceMappingURL=./screen-medium.css.map */